import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from '../../components/seo'

const NewsPage = ({ data }) => (
  <Layout pageTitle="Home">
    <ul>
      {data.allMicrocmsBlog.edges.map(({ node }) => (
        <li key={node.blogId}>
          <Link to={`/news/${node.blogId}`}>{node.title}</Link>
        </li>
      ))}
    </ul>
  </Layout>
)
export const Head = () => <Seo title="My Blog Posts" />


export default NewsPage

export const query = graphql`
  query {
    allMicrocmsBlog {
      edges {
        node {
          blogId
          title
        }
      }
    }
  }
`
